@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes zoom-down {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
  }
}

@keyframes zoom-down {
  from {
    transform: scale(1, 1) translate(0);
  }
  to {
    transform: scale(1.5, 1.5) translate(-100px, -50px);
  }
}

@-webkit-keyframes zoom-up {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
  }
}

@keyframes zoom-up {
  from {
    transform: scale(1, 1) translate(-100px, -50px);
  }
  to {
    transform: scale(1.5, 1.5) translate(0);
  }
}

.carousel-inner > .carousel-item:nth-child(even) > img {
  -webkit-animation: zoom-down 30s;
  animation: zoom-down 30s;
}

.carousel-inner > .carousel-item:nth-child(odd) > img {
  -webkit-animation: zoom-down 30s;
  animation: zoom-down 30s;
}