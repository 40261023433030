@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

p {
    line-height: 28px;
    font-size: 16px;
}

body {
    font-size: 16px;
}

$headings-font-weight: 700;
$headings-color: #404040;
$headings-margin-bottom: 10px;
$headings-font-family: Montserrat;
$headings-line-height: 30px;

$h1-font-size: 40px;
$h2-font-size: 28px;
$h4-font-size: 20px;

$link-color: #387031;
$link-decoration: none;

$font-family-sans-serif: Muli, sans-serif;

$line-height-base: 1.5;

$carousel-caption-color: #FFF;
$carousel-dark-caption-color: #FFF;

$body-bg: #FFF;
$body-color: #606060;

$dark: #151515;

$border-radius: 32px;
$input-border-radius: 4px;
$input-height: 48px;

$input-padding-y: 11px;

$body-tertiary-color: #FFF;
$body-tertiary-bg: rgba(21, 21, 21, 0.9);

$navbar-light-color: rgba(21, 21, 21, 0.9);

$navbar-dark-color: rgba(255, 255, 255, 0.94);

$navbar-padding-y: 0;
$nav-link-font-weight: 700;
$nav-link-font-size: 15px;
$nav-link-color: rgba(255,255,255,0.94);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

$container-max-widths: (
    sm: 420px,
    md: 720px,
    lg: 960px,
    xl: 1140px
);

@import '~bootstrap/scss/bootstrap.scss'